<div class="status-icon" [ngClass]="agent.CurrentEvent.Status.Item.Colour">
  <ion-icon [src]="calcIcon()" class="agentIcon"></ion-icon>
</div>
<div class="agent">
  <div class="first-name">{{agent.First}}</div>
  <div class="last-name">{{agent.Last}}</div>
</div>
<div class="agentInfo">
    <div class="status">{{agent.CurrentEvent.Status.Item.Identifier | titlecase}}</div>
    <div class="time">{{agent.CurrentEvent.Date | timePeriod}}</div>
</div>
