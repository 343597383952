export const MockWallboardStats = {
  data: [
    {
      queueName: 'BasicQueue',
      queueId: '67d91ae3-6a52-4fec-aca8-06e7cde4c07c',
      callsWaiting: 0,
      totalCalls: 10,
      GOS: 80,
      ASA: 15,
      contactsAbandoned: 4,
    },
    {
      queueName: 'Future Student Queue',
      queueId: '6a384920-3c65-4b02-9769-802548343d89',
      callsWaiting: 0,
      totalCalls: 12,
      GOS: 66,
      ASA: 8,
      oldestContact: 292,
    },
    {
      queueName: 'Consegna PreSales Demo',
      queueId: '6e708c39-ed1f-4bbc-90db-26d44a2c3938',
      GOS: 50,
      ASA: 51,
      contactsHandled: 2,
      totalCalls: 2,
      callsWaiting: 1,
      oldestContact: 45,
    },
    {
      queueName: 'Current Student Queue',
      queueId: 'ce8c75c4-14c7-421f-be57-21210683c479',
      callsWaiting: 0,
      totalCalls: 23,
      GOS: 83,
      ASA: 19,
      oldestContact: 3600,
    },
    {
      queueName: 'Case Management Sem Two 2020 Queue',
      queueId: 'de43343b-9d84-4f72-a48f-dd2bf5f858ce',
      callsWaiting: 0,
      totalCalls: 0,
      GOS: 0,
      ASA: 0,
      oldestContact: 60,
    },
  ],
  dataSummary: {
    totalCalls: 2,
    GOS: 50,
    ASA: 51,
    contactsAbandoned: 0,
    contactsHandled: 2,
    callbacksHandled: 0,
    callsWaiting: 1,
  },
};
