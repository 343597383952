<div class="header">
    <h1>Prompt List</h1>
</div>

<!-- check if there are even prompts -->
<div *ngIf="promptList.length === 0">
    <p>There are no prompts to display.</p>
</div>

<div *ngIf="promptList.length > 0">

    <!-- Sort controls -->
    <div class="sort-controls">
        <div class="filter">
            <label for="flow">Filter by Flow</label>

            <select name="flow" id="flow" [(ngModel)]="contactFlowFilter">
                <option *ngFor="let flow of getFlowList()" [value]="flow">
                    {{flow}}
                </option>
            </select>
        </div>
        <div class="filter">
            <label for="keyword">Filter by Keyword</label>

            <select name="flow" id="keyword" [(ngModel)]="keywordFilter">
                <option *ngFor="let keyword of getKeywordList()" [value]="keyword">
                    {{keyword}}
                </option>
            </select>
        </div>
    </div>


    <table class="responsive-table">
        <thead>
            <tr>
                <th rowspan="2">Flow</th>
                <th rowspan="2">Keyword</th>
                <th rowspan="2">Default Message Text</th>
                <th colspan="4">Alternate Message</th>
                <th rowspan="2">Edit</th>
            </tr>
            <tr>
                <th>Text</th>
                <th>Start</th>
                <th>End</th>
                <th>Active</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let prompt of computedPromptList()">
                <td>{{ prompt.flow_name }}</td>
                <td>{{ prompt.keyword }}</td>

                <td *ngIf="prompt.default_prompt && prompt.default_prompt != ''">
                    {{ formatPrompt(prompt.default_prompt) }}
                    <hr/>
                    <app-audio-player [text]="prompt.default_prompt" [api]="api"></app-audio-player>
                </td>
                <td *ngIf="!prompt.default_prompt && prompt.default_prompt == ''">
                    <i>No prompt</i>
                </td>

                <td *ngIf="prompt.custom_prompt && prompt.custom_prompt != ''">
                    {{ formatPrompt(prompt.custom_prompt) }}
                    <hr/>
                    <app-audio-player [text]="prompt.custom_prompt" [api]="api"></app-audio-player>
                </td>
                <td *ngIf="!prompt.custom_prompt && prompt.custom_prompt == ''">
                    <i>No prompt</i>
                </td>

                <!-- Show 'no start time' if the prompt start time is less than 946638000 -->
                <td *ngIf="prompt.start_time && prompt.start_time !== ''">{{getDate(prompt.start_time)}}</td>
                <td *ngIf="!prompt.start_time || prompt.start_time === ''">No start time</td>

                <!-- Show 'no end time' if the prompt start time is less than 946638000 -->
                <td *ngIf="prompt.end_time && prompt.end_time !== ''">{{getDate(prompt.end_time)}}</td>
                <td *ngIf="!prompt.end_time || prompt.end_time === ''">No end time</td>

                <td>{{isPromptActive(prompt) ? 'Yes' : 'No'}}</td>

                <td>
                    <a routerLink="/prompts/edit/{{prompt.flow_name}}/id/{{prompt.keyword}}"
                       [routerLinkActive]="['active']" class="edit">
                         <p><i class="fa fa-edit"></i>Edit</p>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>

</div>
