import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { LoginService } from '@uoa/auth';
import { environment } from '../../../../environments/environment';
import { Prompt } from '../../../domain/interfaces/prompts';
import { of, timer } from 'rxjs';
import { catchError, concatMap, debounce, tap } from 'rxjs/operators';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastController } from '@ionic/angular';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import * as moment from 'moment';

export interface TimeKeeper {
    isoTime: string;
    datetime: number | string;
    disabled: boolean;
}

@Component({
    selector: 'app-prompt-edit',
    templateUrl: './prompt-edit.component.html',
    styleUrls: ['./prompt-edit.component.scss'],
})
export class PromptEditComponent implements OnInit {

    // dialogRef: DialogRef<ConfirmationDialogComponent>;

    // Prompts
    public prompt: Prompt;
    public promptEdit: Prompt;

    start: TimeKeeper;
    end: TimeKeeper;
    isLoaded = false;

    absoluteMinDate: string;
    absoluteMaxDate: string;
    changeableIsStart = false;

    public api: Data;

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private loginService: LoginService,
                private domSanitizer: DomSanitizer, private toastController: ToastController, private confirmationDialog: ConfirmationDialogService,
                private cdRef: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.route.data.subscribe((api) => {
            console.log('Got API: ', api);
            this.api = api;

            // Set min date to 1 year ago and max date to 10 years from now
            this.absoluteMinDate = moment(new Date()).subtract(2, 'year').toISOString(true);
            this.absoluteMaxDate = moment(new Date()).add(10, 'year').toISOString(true);

            if (environment.wallboards.prompt.useMockData) {
                console.log('mock data');
                console.log(environment.wallboards.prompt.audit.mockData.data.Items);

                // Get prompt from route params
                this.prompt = environment.wallboards.prompt.prompts.mockData.data.Items.find(item =>
                    item?.keyword === this.route.snapshot.params.keyword && item?.flow_name === this.route.snapshot.params.flowName);
                this.promptEdit = this.prompt;
                console.log(this.prompt);
            } else {
                console.log('real data');

                // Get the API prompt url
                // tslint:disable-next-line:max-line-length
                const promptUrl = api.prompts.baseUrl + '/' + this.route.snapshot.params.flowName + '/id/' + this.route.snapshot.params.keyword;

                of(null).pipe(
                    // Check if the user is logged in
                    tap(async () => {
                        if (!await this.loginService.isAuthenticated()) {
                            await this.loginService.doLogin();
                        }
                    }),
                    concatMap(() => this.httpClient.get<Prompt>(promptUrl).pipe(
                        tap((prompt) => {
                            console.log('Got prompt', prompt[0]);
                            this.prompt = prompt[0];
                            // Set the new promptEdit
                            this.promptEdit = {
                                ...this.prompt,
                            } as Prompt;

                            this.promptEdit.default_prompt = this.formatPrompt(this.promptEdit.default_prompt);
                            this.promptEdit.custom_prompt = this.formatPrompt(this.promptEdit.custom_prompt);

                            // Set the start and end times
                            this.start = {
                                isoTime: !this.prompt.start_time || this.prompt.start_time === '' || this.prompt.start_time == null
                                    ? moment(Date.now()).toISOString(true)
                                    : this.convertToDate(this.prompt.start_time),
                                datetime: !this.prompt.start_time || this.prompt.start_time === '' || this.prompt.start_time == null
                                    ? moment().unix()
                                    : moment(this.prompt.start_time).unix(),
                                disabled: true
                            };

                            console.log('start', this.start.isoTime);

                            this.end = {
                                isoTime: !this.prompt.end_time || this.prompt.end_time === '' || this.prompt.end_time == null
                                    ? moment(new Date()).toISOString(true)
                                    : this.convertToDate(this.prompt.end_time),
                                datetime: !this.prompt.end_time || this.prompt.end_time === '' || this.prompt.end_time == null
                                    ? moment().unix()
                                    :  moment(this.prompt.end_time).unix(),
                                disabled: true
                            };

                            console.log('end', this.end.isoTime);

                            if(this.start.datetime.toString().length < 13) {
                                // Cast to string
                                this.start.datetime = this.start.datetime.toString();
                                // Add '0' to end of string until it is 10 characters long
                                this.start.datetime = this.start.datetime.toString() + '0'.repeat(13 - this.start.datetime.toString().length);
                                this.start.isoTime = this.convertToDate(Number(this.start.datetime));
                            }

                            if(this.end.datetime.toString().length < 13) {
                                // Cast to string
                                this.end.datetime = this.end.datetime.toString();
                                // Add '0' to end of string until it is 10 characters long
                                this.end.datetime = this.end.datetime.toString() + '0'.repeat(13 - this.end.datetime.toString().length);
                                this.end.isoTime = this.convertToDate(Number(this.end.datetime));
                            }

                            console.log('start done', this.start.isoTime);
                            console.log('end done', this.end.isoTime);

                            this.start.disabled = !this.prompt.start_time || this.prompt.start_time === '';
                            this.end.disabled = !this.prompt.end_time || this.prompt.end_time === '';

                            console.log(this.end.datetime);
                        })
                    )),

                    catchError((err) => {
                        console.log('Error getting prompt', err);
                        return of(null);
                    })
                ).subscribe({
                    next: (polly) => {
                        console.log('done');
                        this.isLoaded = true;
                    }
                });
            }
        });
    }

    onDatetimeChange(event: any, isStart: boolean) {
        event.preventDefault();
        this.changeableIsStart = isStart;

        // If event.target.value is blank, set the time to now
        if (!event.target.value || event.target.value === '' || event.target.value == null) {

            // Check if start time is after end time and change start to day before if so
            if (this.start.datetime > this.end.datetime) {
                this.start.isoTime = this.convertToDate(moment(this.end.isoTime).subtract(1, 'days').toISOString(true));
                this.start.datetime = moment(this.end.isoTime).subtract(1, 'days').unix();
                console.log(this.start.isoTime);
            }

            // Check if end time is before start time and change end to day after if so
            if (this.end.datetime < this.start.datetime) {
                this.end.isoTime = this.convertToDate(moment(this.start.isoTime).add(1, 'days').toISOString(true));
                this.end.datetime = moment(this.start.isoTime).add(1, 'days').unix();
                console.log(this.end.isoTime);
            }
        } else {
            event.target.value = this.getRoundTime(event.target.value);

            const isotime = event.target.value;
            const datetime = moment(isotime).unix();

            // Save date to prompt
            if (isStart) {
                this.start.datetime = datetime;
                this.start.isoTime = isotime;
                console.log(this.start.isoTime);
                console.log(this.start.datetime);
            } else {
                this.end.datetime = datetime;
                this.end.isoTime = isotime;
                console.log(this.end.isoTime);
                console.log(this.end.datetime);
            }
        }

        this.cdRef.detectChanges();
    }

    // Convert unix timestamp to date string
    convertToDate(timestamp: number | string): string {
        if (typeof timestamp === 'number' && isNaN(timestamp)) {
            timestamp = Date.now();
        }
        return moment(new Date(timestamp), moment.ISO_8601).toISOString(true);
    }

    // Date time set to 0
    onDatetimeClear(event: any, isStart: boolean) {
        event.preventDefault();

        if (isStart) {
            this.start.disabled = !this.start.disabled;
        } else {
            this.end.disabled = !this.end.disabled;
        }
    }

    submit($event) {
        $event.preventDefault();

        this.confirmationDialog.keyword = this.prompt.keyword;
        this.confirmationDialog.flow = this.prompt.flow_name;

        // Convert isotime to moment unix then check if start time is after end time and throw error if so
        const start = moment(this.start.isoTime).unix();
        const end = moment(this.end.isoTime).unix();

        if (!this.start.disabled || !this.end.disabled) {
            if (moment(this.start.isoTime).isAfter(this.end.isoTime)) {
                this.toastController.create({
                    message: 'Start time cannot be after end time',
                    duration: 3000,
                    color: 'danger'
                }).then(async (toast) => {
                    await toast.present();
                });
                return;
            }

            // Check if end time is before start time and throw error if so
            if (moment(this.end.isoTime).isBefore(this.start.isoTime)) {
                this.toastController.create({
                    message: 'End time cannot be before start time',
                    duration: 3000,
                    color: 'danger'
                }).then(async (toast) => {
                    await toast.present();
                });
                return;
            }
            if (this.end.isoTime === this.start.isoTime) {
                this.toastController.create({
                    message: 'End time cannot be the same as start time',
                    duration: 3000,
                    color: 'danger'
                }).then(async (toast) => {
                    await toast.present();
                });
                return;
            }
        }

        this.confirmationDialog.open();

        this.confirmationDialog.dialogRef.afterClosed().subscribe((isAccepted) => {
            if (isAccepted) {
                // Get the keyword and flow name from the route params
                const keyword = this.route.snapshot.params.keyword;
                const flowName = this.route.snapshot.params.flowName;

                // Get the URL to update the prompt
                const promptUrl = this.api.prompts.baseUrl + '/' + flowName + '/id/' + keyword;

                let default_prompt = this.promptEdit.default_prompt;

                // Check if this.text starts with and ends with <speak> and </speak> and add them if not
                if (!default_prompt.startsWith('<speak>')) {
                    default_prompt = '<speak>' + default_prompt;
                }
                if (!default_prompt.endsWith('</speak>')) {
                    default_prompt = default_prompt + '</speak>';
                }

                let custom_prompt = this.promptEdit.custom_prompt;

                // Check if this.text starts with and ends with <speak> and </speak> and add them if not
                if (!custom_prompt.startsWith('<speak>')) {
                    custom_prompt = '<speak>' + custom_prompt;
                }
                if (!custom_prompt.endsWith('</speak>')) {
                    custom_prompt = custom_prompt + '</speak>';
                }

                // Send to api
                this.httpClient.put(promptUrl, {
                    default_prompt: default_prompt,
                    custom_prompt: custom_prompt,
                    // Start and end times as unix timestamp
                    start_time: this.start.disabled ? '' : Number(this.start.datetime),
                    end_time: this.end.disabled ? '' : Number(this.end.datetime),
                }).subscribe({
                    next: (data: Prompt) => {
                        console.log('after put', data);
                        this.prompt = data;

                        this.toastController.create({
                            message: 'Successfully updated prompt',
                            position: 'top',
                            duration: 2000,
                        }).then(async (toast) => {
                            await toast.present();
                        });

                        // Redirect to the prompt list page
                        this.router.navigate(['/prompts']);
                    },
                    error: (error) => {
                        console.log('error', error);

                        this.toastController.create({
                            message: 'Error updating prompt',
                            position: 'top',
                            duration: 2000,
                        }).then(async (toast) => {
                            await toast.present();
                        });
                    }
                });
            } else {
                console.log('not accepted');
            }
        });
    }

    isPromptActive() {
        if (this.start.disabled && this.end.disabled) return true;

        // If end time is disabled and start time is before current time, return true
        if (this.end.disabled && moment().isAfter(this.start.isoTime)) {
            return true;
        }

        // If start time is disabled and end time is after current time, return true
        if (this.start.disabled && moment().isBefore(this.end.isoTime)) {
            return true;
        }

        // Both are assumed to be enabled

        if(!this.changeableIsStart) {
            // Check whether end time is after start time and if so, change start time to day before end time
            if (moment(this.end.isoTime).isBefore(this.start.isoTime)) {
                this.start.isoTime = this.convertToDate(moment(this.end.isoTime).subtract(1, 'days').toISOString(true));
                this.start.datetime = moment(this.end.isoTime).subtract(1, 'days').unix();
                console.log(this.start.isoTime);
            }
        } else {
            // Check whether start time is after end time and if so, change end time to day after start time
            if (moment(this.start.isoTime).isAfter(this.end.isoTime)) {
                this.end.isoTime = this.convertToDate(moment(this.start.isoTime).add(1, 'days').toISOString(true));
                this.end.datetime = moment(this.start.isoTime).add(1, 'days').unix();
                console.log(this.end.isoTime);
            }
        }

        // Return true if current time is between start and end time
        return moment().isBetween(this.start.isoTime, this.end.isoTime);
    }

    getMaxTime(isStart: boolean) {
        if (isStart) {
            return this.end.disabled ? this.absoluteMaxDate : this.end.isoTime;
        } else {
            return this.absoluteMaxDate;
        }
    }

    getMinTime(isStart: boolean) {
        if (isStart) {
            return this.absoluteMinDate;
        } else {
            return this.start.disabled ? this.absoluteMinDate : this.start.isoTime;
        }
    }

    getRoundTime(time: number) {
        return moment(time).startOf('hour').add(Math.round(moment(time).minutes() / 15) * 15, 'minutes').toISOString(true);
    }

    formatPrompt(text: string) {
        // Remove <speak> tags
        return text.replace('<speak>', '').replace('</speak>', '');
    }

    onBlur($event, isStart: boolean) {
        // Round $event.target.value to nearest 15 minutes
        const rounded = this.getRoundTime($event.target.value);
        // Set time to rounded time
        if (isStart) {
            this.start.isoTime = rounded;
            console.log(moment(rounded).unix());
            this.start.datetime = moment(rounded).unix();
        } else {
            this.end.isoTime = rounded;
            console.log(moment(rounded).unix());
            this.end.datetime = moment(rounded).unix();
        }
    }
}
