export const MockWallboardAgents: any = {
  message: 'OK',
  data: {
    Items: [
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:37:06.487Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Inbound',
              Icon: 'phone',
              Identifier: 'available',
              Colour: 'green',
            },
          },
          Date: '2022-03-24T21:37:06.487Z',
        },
        First: 'Justin',
      },
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:38:06.369Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Breaks/Other duties',
              Icon: 'person',
              Identifier: 'lunch',
              Colour: 'red',
            },
          },
          Date: '2022-03-24T21:38:06.369Z',
        },
        First: 'Justin',
      },
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:37:06.487Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Inbound',
              Icon: 'phone',
              Identifier: 'available',
              Colour: 'green',
            },
          },
          Date: '2022-03-24T21:37:06.487Z',
        },
        First: 'Justin',
      },
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:38:06.369Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Breaks/Other duties',
              Icon: 'person',
              Identifier: 'lunch',
              Colour: 'red',
            },
          },
          Date: '2022-03-24T21:38:06.369Z',
        },
        First: 'Justin',
      },
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:38:06.369Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Breaks/Other duties',
              Icon: 'person',
              Identifier: 'lunch',
              Colour: 'red',
            },
          },
          Date: '2022-03-24T21:38:06.369Z',
        },
        First: 'Justin',
      },
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:38:06.369Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Breaks/Other duties',
              Icon: 'person',
              Identifier: 'lunch',
              Colour: 'red',
            },
          },
          Date: '2022-03-24T21:38:06.369Z',
        },
        First: 'Justin',
      },
      {
        FullAgentName: 'Justin S',
        Last: 'Susans',
        Identifier:
          'arn:aws:connect:ap-southeast-2:000231323619:instance/6b5f4209-27fc-4e02-8e36-207396617b35/agent/66a38613-6c73-4006-856e-4de8df23e2fb',
        LastUpdatedDate: '2022-03-24T21:38:06.369Z',
        CurrentEvent: {
          Status: {
            Item: {
              Visible: 'true',
              GroupName: 'Breaks/Other duties',
              Icon: 'person',
              Identifier: 'lunch',
              Colour: 'red',
            },
          },
          Date: '2022-03-24T21:38:06.369Z',
        },
        First: 'Justin',
      },
    ],

    Count: 2,
    ScannedCount: 2,
  },
};
