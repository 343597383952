import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.scss'],
})
export class AgentCardComponent {
  @Input() agent: any;

  constructor() { }

  calcIcon() {
    const status = this.agent.CurrentEvent.Status.Item.Icon;
    return `assets/icon/${status}.svg`;
  }

}
