import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timePeriod',
})
export class TimePeriodPipe implements PipeTransform {
  transform(input: string): any {
    if (!input) return input;
    
    const startTime = new Date(input);
    const endTime = Date.now();
    const timeSeconds = Math.floor((endTime - startTime.getTime()) / 1000);
    
    if (timeSeconds > 60 * 60 * 24) {
      return "> 1 day"
    } else {
      const time = new Date(1000 * timeSeconds).toISOString();
      return time.substr(11, 8).toLocaleLowerCase();
    }

  }
}
