import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-frame',
  templateUrl: './card-frame.component.html',
  styleUrls: ['./card-frame.component.scss'],
})
export class CardFrameComponent  {
  @Input() title: string;

  constructor() { }
}
